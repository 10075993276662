<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="window px-5 pt-4 pb-16">
          <v-row class="mb-1 mt-0">
          <div v-if="!isSubagent">
              <v-btn tile class="ml-2 mr-0" elevation="0" :dark="!isRailway" :color="!isRailway ? 'orange' : ''" @click="() => {
                isRailway = false;
              }
              ">
              авиа
            </v-btn>
            <v-btn tile class="mx-0" elevation="0" :dark="isRailway" :color="isRailway ? 'orange' : ''"
            @click="() => {
              isRailway = true;
            }
            ">
            жд
          </v-btn>
        </div>
          </v-row>
          <v-row justify="center" class="mb-2">
            <h1>Найти Рейс {{isSubagent ? "" : ("(" + (isRailway ? "ЖД" : "Авиа") + ")")}}</h1>
          </v-row>
          <v-row justify="center" class="mb-1 mt-10">
            <v-btn class="mx-1" elevation="0" :dark="oneway" :color="oneway ? 'orange' : ''" @click="() => {
                date.To = '';
                oneway = true;
              }
              ">
              в одну сторону
            </v-btn>
            <v-btn class="mx-1" elevation="0" :dark="!oneway" :color="!oneway ? 'orange' : ''" @click="() => {
                date.To = date.From;
                oneway = false;
              }
              ">
              туда и обратно
            </v-btn>
          </v-row>
          <v-divider></v-divider>
          <v-row justify="center">
            <v-col>
              <v-form v-model="valid" ref="form" :action="!isRailway ? '/found-tickets' : 'trs-list'" @submit="submit">
                <v-row>
                  <v-col cols="12" md="5">
                    <input-town
                        v-model="depCity"
                        label="Откуда"
                        :start="startCity"
                        :endpoint="isRailway ? this.$enums.Endpoints.RailwayData.RailwayStantions : this.$enums.Endpoints.Data.Stations"
                        :is-railway="isRailway"
                    ></input-town>
                  </v-col>
                  <v-spacer />
                  <v-col cols="1" class="text-center" align-self="center">
                    <v-btn block color="orange" @click="swapCities" tabindex="-1">
                      <v-icon large>mdi-swap-horizontal</v-icon>
                    </v-btn>
                  </v-col>
                  <v-spacer />
                  <v-col cols="12" md="5">
                    <input-town
                        v-model="arrCity"
                        label="Куда"
                        start=""
                        :endpoint="isRailway ? this.$enums.Endpoints.RailwayData.RailwayStantions : this.$enums.Endpoints.Data.Stations"
                        :is-railway="isRailway"
                    ></input-town>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6">
                    <custom-date-picker v-model="date.From" :min="date.Today" no-title format="dd.MM.yyyy">
                    </custom-date-picker>
                  </v-col>
                  <v-col>
                    <custom-date-picker v-model="date.To" no-title :min="date.from" :disabled="oneway"
                                        format="dd.MM.yyyy">
                    </custom-date-picker>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4" :md="isRailway ? 4 : 3">
                    <v-text-field type="number" min="0" label="Взрослые" v-model="adults" required>
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" :md="isRailway ? 4 : 3">
                    <v-text-field type="number" min="0" label="Дети" v-model="kids">
                    </v-text-field>
                  </v-col>
                  <v-col cols="4" :md="isRailway ? 4 : 3">
                    <v-text-field type="number" min="0" label="Младенцы" v-model="babe">
                    </v-text-field>
                  </v-col>
                  <v-col v-if="!isRailway" cols="12" md="3">
                    <v-select label="Класс" :items="classes" v-model="fClass" required></v-select>
                  </v-col>
                </v-row>

                <v-row class="mt-4 mx-0" justify="end">
                  <v-btn :disabled="isSearchDisabled" block dark type="submit" elevation="0" color="orange">
                    Найти Рейс
                  </v-btn>
                  <!--                   <v-btn @click="correction">correction</v-btn>-->
                </v-row>
                <div v-if="tickets">
                  <div v-for="ticket in tickets" :key="ticket.id">
                    <h2>{{ ticket.trainNumber }}</h2>
                    <p>Departure: {{ ticket.departureDateTime }}</p>
                    <p>Arrival: {{ ticket.arrivalDateTime }}</p>
                    <p>Duration: {{ ticket.tripDuration }}</p>
                    <div v-for="carGroup in ticket.carGroups" :key="carGroup.carType">
                      <h3>{{ carGroup.carType }}</h3>
                      <p>Min Ticket Price: {{ carGroup.minTicketPrice }}</p>
                      <p>Max Ticket Price: {{ carGroup.maxTicketPrice }}</p>
                    </div>
                  </div>
                </div>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
import DateFormat from "@/mixins/DateFormat";
import { format } from "date-fns";
import InputTown from "../components/UI/InputTown.vue";
import axios from "axios";

export default {
  name: "FindTkt",
  mixins: [DateFormat],
  components: {
    InputTown,
    CustomDatePicker,
  },
  data() {
    return {
      date: {
        From: new Date(),
        To: new Date(),
        Today: new Date(),
      },
      valid: false,
      oneway: true,
      adults: 1,
      kids: 0,
      babe: 0,
      fClass: 0,
      classes: [
        {text: "Эконом", value: 0},
        {text: "Бизнес", value: 1},
      ],
      //-----------------------------------------//
      city: '',
      arrCity: {
        text: "",
        value: {
          code: "",
          name: ""
        },
      },
      depCity: {
        text: "",
        value: {
          code: "",
          name: ""
        }
      },
      dep: 'departure',
      arr: 'arr',
      //-----------------------------------------//
      // Далее - для ЖД, вынести в компонент
      isRailway: false,
      railwayCities: [],
      timerFilter: null,
      tickets: null,
      isLoading: false,
    };
  },
  mounted() {
    this.$store.dispatch('clearTrainSegments')
  },


  computed: {
    isSearchDisabled() {
      return !this.oneway && this.date.From > this.date.To;
    },
    startCity() {
      let dict = new Map([
        [1707, 'LED'], // Пулково
        [2009, 'MOW'], // Шереметьево
        [2010, 'MOW'], // Домодедово
      ])
      let code = dict.get(this.$store.getters.user.airportId);
      return code ?? '';
    },
    isSubagent() {
      return this.$store.getters.user.role == this.$enums.Roles.SubAgent;
    },
  },

  watch: {
    isRailway(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.depCity = {
          text: "",
          value: {
            code: "",
            name: ""
          }
        };
        this.arrCity = {
          text: "",
          value: {
            code: "",
            name: ""
          }
        };
      }
    }
  },



  methods: {
    swapCities() {
      let temp = this.depCity;
      this.depCity = this.arrCity;
      this.arrCity = temp;
    },

    submit(event) {
      event.preventDefault();
      if (this.$refs.form.validate()) {
        const searchData = {
          from: this.depCity.text,
          to: this.arrCity.text,
          date: format(this.date.From, "yyyy-MM-dd"),
          adult: this.adults,
          child: this.kids,
          infant: this.babe
        };

        if (this.isRailway && this.oneway) {
          this.$router.push(`/trs-list?src=${this.depCity.text}&dest=${this.arrCity.text}&codeSrc=${this.depCity.value.code}&codeDest=${this.arrCity.value.code}&date=${searchData.date}&adults=${this.adults}&kids=${this.kids}&bbs=${this.babe}&oneway=true`);
        }

        if (this.isRailway && !this.oneway) {
          this.$router.push(`/trs-list?src=${this.depCity.text}&dest=${this.arrCity.text}&codeSrc=${this.depCity.value.code}&codeDest=${this.arrCity.value.code}&date=${searchData.date}&adults=${this.adults}&kids=${this.kids}&bbs=${this.babe}&oneway=${this.oneway}&dateFrom=${format(this.date.To, "yyyy.MM.dd")}`);
        }

        if (!this.isRailway || (this.isRailway && this.oneway === undefined)) {
          this.$router.push(`/found-tickets?src=${this.depCity.value.code}&dest=${this.arrCity.value.code}&date=${searchData.date}&back=${this.oneway ? "" : format(this.date.To, "yyyy-MM-dd") ?? ""}&cls=${this.fClass}&adults=${searchData.adult}&kids=${searchData.child}&bbs=${searchData.infant}`);
        }
      }
    },

    async correction() {
      let req = {
        type: "sellReturnCorrection1",
        taxationType: "usnIncomeOutcome",
        electronically: true,
        ignoreNonFiscalPrintErrors: true,
        correctionType: "self",
        correctionBaseDate: "2021.08.11",
        correctionBaseNumber: "Без номера",
        correctionBaseName: "Чек коррекции в связи с технической ошибкой",
        operator: {name: "fffff", vatin: "123654789507"},
        clientInfo: {emailOrPhone: "it@bpo.travel"},
        items: [
          {
            type: "position",
            name: "gfff",
            price: 1.0,
            quantity: 1.0,
            amount: 1.0,
            infoDiscountAmount: 0.0,
            department: 1.0,
            measurementUnit: "шт.",
            paymentMethod: "fullPayment",
            paymentObject: "commodity",
            nomenclatureCode: null,
            tax: {type: "none"},
            //agentInfo: {agents: ["commissionAgent"]},
            //supplierInfo: null
          },
        ],
        payments: [{type: "cash", sum: 1.0}],
        //taxes: null
      };
      let uuid = Date().toString();
      let sellReq = {
        uuid: uuid,
        request: req,
      };
      let resp = await this.$axios.post(this.$store.state.kktUrl, sellReq);
      window.setTimeout(async () => {
        let resp1 = await this.$axios.get(
            this.$store.state.kktUrl + "/" + uuid
        );
      }, 5000);
    },
  },
};
</script>

<style scoped></style>
